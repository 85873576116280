import React from 'react'
import { connect } from 'react-redux'
import Header from '../components/shared/Header'
import AddEntitlementsForm from '../components/AddEntitlementsForm'

const AddEntitlements = (): any => (
    <div>
        <Header />
        <AddEntitlementsForm />
    </div>
)

export default connect(
    null,
    null,
)(AddEntitlements)
