import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const IndexComponent = (props: any): any => {
    const { loggedIn } = props
    return (
        loggedIn
            ? <Redirect exact to="/dashboard" />
            : <Redirect exact to="/login" />
    )
}

export default connect()(IndexComponent)
