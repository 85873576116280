import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    addEntitlements,
    clearAddEntitlementsSuccess,
    getCurrentEntitlementsList,
    getEntitlementsList,
} from '../actions/index'
import addEntitlementsStyles from '../styles/addEntitlements.module.css'
import sectionStyles from '../styles/section.module.css'

class AddEntitlementsForm extends Component<any, any> {
    state: ComponentState = {
        clearEntitlementsAddedMessage: false,
        clearSearchMessage: false,
        searchTerm: '',
        selectedEntitlements: [],
    }

    /* <----- Component lifecycle methods -----> */
    componentDidUpdate(prevProps: ComponentDidUpdateInterface): void {
        const { currentEntitlements } = this.props

        if (currentEntitlements !== prevProps.currentEntitlements) {
            const stringifiedCurrentEntitlements = currentEntitlements.map(
                (element: any) => element.id.toString(),
            )
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ selectedEntitlements: stringifiedCurrentEntitlements })
        }
    }

    /* <----- Change and submit handlers -----> */
    handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name } = e.target
        let value = null

        if (e.target.type === 'checkbox') {
            const { selectedEntitlements } = this.state
            const idx = selectedEntitlements.indexOf(name)
            if (idx === -1) {
                selectedEntitlements.push(name)
            } else {
                selectedEntitlements.splice(idx, 1)
            }
            return this.setState({ selectedEntitlements: [...selectedEntitlements] })
        }

        value = e.target.value

        return this.setState({
            [name]: value,
        })
    }

    handleSearch = (e: React.FormEvent<HTMLElement>): void => {
        e.preventDefault()

        const { searchTerm } = this.state
        const {
            clearAddEntitlementsSuccess,
            getEntitlementsList,
            getCurrentEntitlementsList
        } = this.props

        getEntitlementsList(searchTerm)
        getCurrentEntitlementsList(searchTerm)
        clearAddEntitlementsSuccess()

        this.setState({
            clearEntitlementsAddedMessage: true,
            clearSearchMessage: false,
        })
    }

    handleSubmit = (e: React.FormEvent<HTMLElement>): any => {
        e.preventDefault()

        const { addEntitlements } = this.props

        addEntitlements(this.state)

        this.setState({
            clearEntitlementsAddedMessage: false,
            clearSearchMessage: true,
            searchTerm: '',
        })
    }

    /* <----- Search form components -----> */
    renderSearchForm = (): React.ReactNode => (
        <React.Fragment>
            <form className={addEntitlementsStyles.form} onSubmit={this.handleSearch}>
                <h1 className={sectionStyles.header}>Entitlements</h1>
                <label className={addEntitlementsStyles.componentLabel} htmlFor="searchTerm"> {/* eslint-disable-line*/}
                    {'Board Identifier:\n'}
                    <span className={addEntitlementsStyles.component}>
                        (Serial Number, Barcode, or Fingerprint)
                    </span>
                </label>
                <input
                    className={addEntitlementsStyles.component}
                    type="text"
                    name="searchTerm"
                    // eslint-disable-next-line react/destructuring-assignment
                    value={this.state.searchTerm}
                    onChange={this.handleChange}
                />
                <input
                    className={addEntitlementsStyles.submitButton}
                    value="Search"
                    type="submit"
                />
            </form>
            {this.renderSearchSuccessorError()}
        </React.Fragment>
    )

    renderSearchSuccessorError = (): React.ReactElement | null => {
        const {
            getCurrentEntitlementsListError,
            getCurrentEntitlementsListSuccess,
            getEntitlementsListError,
        } = this.props

        const { clearSearchMessage } = this.state

        if (clearSearchMessage) {
            return null
        }

        if (getEntitlementsListError) {
            return (
                <h3 className={addEntitlementsStyles.error}>
                    {`Error:\n${getEntitlementsListError}`}
                </h3>
            )
        }
        if (getCurrentEntitlementsListError) {
            return (
                <h3 className={addEntitlementsStyles.error}>
                    {`Error:\n${getCurrentEntitlementsListError}`}
                </h3>
            )
        } if (getCurrentEntitlementsListSuccess) {
            return (
                <h3 className={addEntitlementsStyles.success}>
                    {'Board Found\nCurrent Entitlements Indicated'}
                </h3>
            )
        }
        return null
    }

    /* <----- Entitlements form components -----> */
    renderEntitlementsForm = (): React.ReactElement | null => {
        const { entitlements, addEntitlementsSuccess } = this.props
        const { selectedEntitlements } = this.state
        if (entitlements.length) {
            return (
                <React.Fragment>
                    <form className={addEntitlementsStyles.form} onSubmit={this.handleSubmit}>
                        <div className={addEntitlementsStyles.componentLabel}>
                            Entitlements Selection:
                        </div>
                        <div
                            className={addEntitlementsStyles.checkboxContainer}
                        >
                            {
                                entitlements.map((elem: {id: number, name: string, sku: string}) => {
                                    return (
                                        <React.Fragment key={elem.id}>
                                            <input
                                                className={addEntitlementsStyles.checkboxCheckbox}
                                                type="checkbox"
                                                name={`${elem.id}`}
                                                checked={selectedEntitlements.includes(`${elem.id}`)}
                                                disabled={addEntitlementsSuccess}
                                                onChange={(e): any => this.handleChange(e)}
                                            />
                                            <label
                                                className={addEntitlementsStyles.checkboxLabel}
                                                htmlFor={`${elem.id}`}
                                            >
                                                {`${elem.name}\n(${elem.sku})`}
                                            </label>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                        <input
                            className={addEntitlementsStyles.submitButton}
                            value="Submit"
                            type="submit"
                        />
                    </form>
                    {this.renderAddEntitlementsSuccessOrError()}
                </React.Fragment>
            )
        }
        return null
    }

    renderAddEntitlementsSuccessOrError = (): React.ReactElement | null => {
        const { addEntitlementsError, addEntitlementsSuccess } = this.props
        const { clearEntitlementsAddedMessage } = this.state

        if (clearEntitlementsAddedMessage) {
            return null
        }
        if (addEntitlementsSuccess) {
            return <h3 className={addEntitlementsStyles.success}>Entitlement(s) Modified</h3>
        }
        if (addEntitlementsError) {
            return (
                <h3 className={addEntitlementsStyles.error}>
                    {`Error:\n${addEntitlementsError}`}
                </h3>
            )
        }
        return null
    }

    /* <----- Render proper -----> */
    render(): React.ReactNode {
        return (
            <React.Fragment>
                {this.renderSearchForm()}
                {this.renderEntitlementsForm()}
            </React.Fragment>
        )
    }
}

/* <----- Redux mapping functions -----> */
const mapStateToProps = (state: MapStateToPropsInterface): object => (
    {
        addEntitlementsError: state.addEntitlementsError,
        addEntitlementsSuccess: state.addEntitlementsSuccess,
        currentEntitlements: state.currentEntitlements,
        getCurrentEntitlementsListError: state.getCurrentEntitlementsListError,
        getCurrentEntitlementsListSuccess: state.getCurrentEntitlementsListSuccess,
        getEntitlementsListError: state.getEntitlementsListError,
        entitlements: state.entitlements,
    }
)

const mapDispatchToProps = (dispatch: Function): object => (
    {
        addEntitlements: (state: {searchTerm: string, selectedEntitlements: string[]}): void => (
            dispatch(addEntitlements(state))
        ),
        clearAddEntitlementsSuccess: (): void => dispatch(clearAddEntitlementsSuccess()),
        getCurrentEntitlementsList: (searchTerm: string): void => (
            dispatch(getCurrentEntitlementsList(searchTerm))
        ),
        getEntitlementsList: (searchTerm: string): void => (
            dispatch(getEntitlementsList(searchTerm))
        ),
    }
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddEntitlementsForm)

/* <----- Interfaces-----> */
interface ComponentState {
    clearEntitlementsAddedMessage: boolean
    clearSearchMessage: boolean
    searchTerm: string
    selectedEntitlements: string[]
}

interface MapStateToPropsInterface {
    addEntitlementsError: string
    addEntitlementsSuccess: string
    currentEntitlements: number[]
    entitlements: string[]
    getCurrentEntitlementsListError: string
    getCurrentEntitlementsListSuccess: string
    getEntitlementsListError: string
}

interface ComponentDidUpdateInterface {
    currentEntitlements: number[]
}
