import {
    createStore,
    applyMiddleware,
    compose,
} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../reducers/index'

declare global {
    interface Window { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any }
}

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
    rootReducer,
    storeEnhancers(applyMiddleware(thunk)),
)

export default store
