import React from 'react'
import { connect } from 'react-redux'
import Header from '../components/shared/Header'
import LotNumberForm from '../components/LotNumberForm'

const LotNumber = (): any => (
    <div>
        <Header />
        <LotNumberForm />
    </div>
)

export default connect(
    null,
    null,
)(LotNumber)
