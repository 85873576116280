import axios from 'axios'
import instance from './axiosInstance'
import {
    ACCESS_TOKEN_NAME,
    REFRESH_TOKEN_NAME,
} from '../constants/action-types'
import { logout } from '../actions'

const BASE_URL = process.env.REACT_APP_BASE_URL

const refreshAccessToken = async (): Promise<any> => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME)
    if (refreshToken) {
        const data: any = { refresh: refreshToken }
        const res = await axios.post(
            `${BASE_URL}/api/token/refresh/`,
            data,
        )

        const { access: accessToken } = res.data
        return accessToken
    }
}

const initInterceptors = (store: any): any => {
    instance.interceptors.request.use(
        (config): any => {
            if (!config.headers.common.Authorization) {
                const token = localStorage.getItem(ACCESS_TOKEN_NAME)
                if (token) {
                    instance.defaults.headers.common.Authorization = `Bearer ${token}`
                    config.headers.Authorization = `Bearer ${token}`
                }
            }
            return config
        },
        (error): any => (
            Promise.reject(error)
        ),
    )

    const { dispatch } = store
    instance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true
                try {
                    const accessToken = await refreshAccessToken()

                    localStorage.setItem(ACCESS_TOKEN_NAME, accessToken)

                    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
                    originalRequest.headers.Authorization = `Bearer ${accessToken}`

                    return instance(originalRequest)
                } catch (errRes) {
                    if (errRes.response.status === 401) {
                        return dispatch(logout('Expired credentials - login again.'))
                    }
                }
            }
            return Promise.reject(error);
        },
    )
}

export default initInterceptors
