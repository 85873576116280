import React from 'react'
import { connect } from 'react-redux'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

import IndexComponent from './components/IndexComponent'
import PrivateRoute from './components/shared/PrivateRoute'

import Login from './pages/LoginPage'
import AddEntitlements from './pages/AddEntitlementsPage'
import BoardSearch from './pages/BoardSearchPage'
import BuildUpload from './pages/BuildUploadPage'
import Dashboard from './pages/DashboardPage'
import LotNumber from './pages/LotNumberPage'
import NotFound404 from './pages/NotFound404Page'

import { ACCESS_TOKEN_NAME } from './constants/action-types'
import { loginWithValidStoredCreds } from './actions'

const App = (props: any): React.ReactElement => {
    const { loginWithValidStoredCreds: login } = props
    const token = localStorage.getItem(ACCESS_TOKEN_NAME)

    if (token) {
        login()
    }

    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <IndexComponent />
                </Route>
                <Route path="/login">
                    <Login />
                </Route>
                <PrivateRoute path="/dashboard">
                    <Dashboard />
                </PrivateRoute>
                <PrivateRoute path="/boardsearch">
                    <BoardSearch />
                </PrivateRoute>
                <PrivateRoute path="/buildupload">
                    <BuildUpload />
                </PrivateRoute>
                <PrivateRoute path="/entitlements">
                    <AddEntitlements />
                </PrivateRoute>
                <PrivateRoute path="/lotnumber">
                    <LotNumber />
                </PrivateRoute>
                <Route>
                    <NotFound404 />
                </Route>
            </Switch>
        </Router>
    )
}

const mapDispatchToProps = (dispatch: Function): any => (
    { loginWithValidStoredCreds: (): void => dispatch(loginWithValidStoredCreds()) }
)

export default connect(
    null,
    mapDispatchToProps,
)(App)
