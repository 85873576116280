import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    getChipList,
    getProductList,
    getRevisionList,
    getPbuldList,
    uploadBuild,
} from '../actions/index'
import buildUploadStyles from '../styles/buildUpload.module.css'

// TODO: Consolidate interfaces.
const initialState = {
    productSpecificRevisions: [],
    productSpecificPbulds: [],
    applicableRevisions: {},
    applicablePbulds: {},
    newBuild: {
        product: null,
        build_number: '', // eslint-disable-line @typescript-eslint/camelcase
        version: '',
        chip: '',
        firmware: null,
        released: false,
        revisions: [],
        pbulds: [],
    },
    clearBuildUploadMessage: false,
}
class BuildUploadForm extends Component<any, any> {
    state: any = initialState

    fileInputRef: any = React.createRef()

    /* <----- Component lifecycle methods -----> */
    componentDidMount(): void {
        const {
            getChipList,
            getProductList,
            getRevisionList,
            getPbuldList,
        } = this.props
        getProductList()
        getRevisionList()
        getPbuldList()
        getChipList()
    }


    componentDidUpdate(prevProps: any, prevState: any): void {
        if (this.state.newBuild.product !== prevState.newBuild.product) {
            let productId = 0
            const { product } = this.state.newBuild
            for (const elem of this.props.products) { {/* eslint-disable-line*/}
                if (elem.sku === product) {
                    productId = elem.id
                    break
                }
            }
            const productSpecificRevisions: object[] = []
            const applicableRevisions: any = {}
            const productSpecificPbulds: object[] = []
            const applicablePbulds: any = {}
            const clearBuildUploadMessage = true
            const { revisionList, pbuldList } = this.props

            // Revisions
            for (const elem of revisionList) { {/* eslint-disable-line*/}
                if (elem.product === productId) {
                    productSpecificRevisions.push(elem)
                    applicableRevisions[elem.id] = elem.active
                }
            }
            productSpecificRevisions.sort((a: any, b: any) => {
                if (a.tag < b.tag) {
                    return -1
                }
                if (a.tag > b.tag) {
                    return 1
                }
                return 0
            })

            // pbulds
            for (const elem of pbuldList) { {/* eslint-disable-line*/}
                if (elem.product === productId) {
                    productSpecificPbulds.push(elem)
                    applicablePbulds[elem.id] = elem.active
                }
            }
            productSpecificPbulds.sort((a: any, b: any) => {
                if (a.tag < b.tag) {
                    return -1
                }
                if (a.tag > b.tag) {
                    return 1
                }
                return 0
            })


            this.setState({ // eslint-disable-line
                ...this.state, // eslint-disable-line
                applicablePbulds,
                applicableRevisions,
                productSpecificPbulds,
                productSpecificRevisions,
                clearBuildUploadMessage,
            })
        }

        if (this.props.uploadBuildSuccess !== prevProps.uploadBuildSuccess) {
            this.fileInputRef.value = ''
            this.setState(initialState)  // eslint-disable-line
        }
    }

    /* <----- Data Handler Methods -----> */
    handleChange = (e: React.ChangeEvent<HTMLInputElement> | any, nestedObjectKey = ''): void => {
        const { name } = e.target
        let value = null

        if (e.target.type === 'checkbox') {
            value = e.target.checked
        } else if (e.target.type === 'file') {
            [value] = e.target.files
        } else {
            value = e.target.value
        }

        let newState
        if (nestedObjectKey) {
            newState = {
                [nestedObjectKey]: {
                    ...this.state[nestedObjectKey],
                    [name]: value,
                },
            }
        } else {
            newState = { [name]: value }
        }

        this.setState(newState)
    }


    handleBuildSubmit = (e: React.FormEvent<HTMLElement>): void => {
        e.preventDefault()

        const { applicablePbulds, applicableRevisions, newBuild } = this.state
        const { uploadBuild } = this.props
        const finalRevs: number[] = []
        const finalPbulds: number[] = []

        // Revisions
        Object.entries(applicableRevisions).map(([key, value]: [any, any]): number | null => {
            if (value) {
                return finalRevs.push(key)
            }
            return null
        })
        newBuild.revisions = JSON.stringify(finalRevs)

        // pbulds
        Object.entries(applicablePbulds).map(([key, value]: [any, any]): number | null => {
            if (value) {
                return finalPbulds.push(key)
            }
            return null
        })
        newBuild.pbulds = JSON.stringify(finalPbulds)

        uploadBuild(newBuild)
        this.setState({ clearBuildUploadMessage: false })
    }

    /* <----- Form Component Generation methods -----> */
    generateTextInput = (name: string, placeholderText: string, nestedStateObjectKey = ''): React.ReactElement => {
        const nameArr = name.split('_')
        const cleanNameArr: string[] = []
        nameArr.map((elem) => (
            cleanNameArr.push(elem.charAt(0).toUpperCase() + elem.slice(1))
        ))
        const labelName = cleanNameArr.join(' ')
        return (
            <React.Fragment>
                <label
                    className={buildUploadStyles.formComponent}
                    htmlFor={name}
                >
                    {labelName}
                    :
                </label>
                <input
                    className={buildUploadStyles.formComponent}
                    type="text"
                    name={name}
                    placeholder={placeholderText}
                    value={this.state.newBuild[name]}
                    onChange={(e): any => this.handleChange(e, `${nestedStateObjectKey}`)}
                />
            </React.Fragment>
        )
    }


    generateRevisionCheckboxes = (): React.ReactElement | null => {
        const {
            productSpecificRevisions,
            applicableRevisions,
            newBuild,
        } = this.state

        if (productSpecificRevisions.length) {
            return (
                <React.Fragment>
                    <h5 className={buildUploadStyles.checkboxesHeader}>
                        {`${newBuild.product}\nApplicable\nRevisions:`}
                    </h5>
                    <div className={buildUploadStyles.checkboxesContainer}>
                        {
                            productSpecificRevisions.map((elem: {tag: string, id: number, url: string}) => (
                                <React.Fragment key={elem.id}>
                                    <label
                                        className={buildUploadStyles.checkboxesLabel}
                                        htmlFor={`${elem.id}`}
                                    >
                                        {`${elem.tag}:`}
                                    </label>
                                    <input
                                        className={buildUploadStyles.checkboxesCheckbox}
                                        type="checkbox"
                                        name={`${elem.id}`}
                                        checked={applicableRevisions[elem.id]}
                                        onChange={(e): any => this.handleChange(e, 'applicableRevisions')}
                                    />
                                </React.Fragment>
                            ))
                        }
                    </div>
                </React.Fragment>
            )
        }
        return null
    }

    generatePbuldCheckboxes = (): React.ReactElement | null => {
        const {
            productSpecificPbulds,
            applicablePbulds,
            newBuild,
        } = this.state

        if (productSpecificPbulds.length) {
            return (
                <React.Fragment>
                    <h5 className={buildUploadStyles.checkboxesHeader}>
                        {`${newBuild.product}\nApplicable\nPbulds:`}
                    </h5>
                    <div className={`${buildUploadStyles.checkboxesContainer} ${buildUploadStyles.pbuldsCheckboxesContainer}`}>
                        {
                            productSpecificPbulds.map((elem: {pbuld: string, id: number, url: string}) => (
                                <React.Fragment key={elem.id}>
                                    <label
                                        className={buildUploadStyles.checkboxesLabel}
                                        htmlFor={`${elem.id}`}
                                    >
                                        {`${elem.pbuld}:`}
                                    </label>
                                    <input
                                        className={buildUploadStyles.checkboxesCheckbox}
                                        type="checkbox"
                                        name={`${elem.id}`}
                                        checked={applicablePbulds[elem.id]}
                                        onChange={(e): any => this.handleChange(e, 'applicablePbulds')}
                                    />
                                </React.Fragment>
                            ))
                        }
                    </div>
                </React.Fragment>
            )
        }
        return null
    }


    renderUploadSuccessOrError = (clearBuildUploadMessage: boolean): React.ReactNode | null => {
        const { uploadBuildError, uploadBuildSuccess } = this.props

        if (clearBuildUploadMessage) {
            return null
        }
        if (uploadBuildSuccess) {
            return <h3 className={buildUploadStyles.uploadBuildSuccess}>Upload Succeeded</h3>
        } if (uploadBuildError) {
            return <h3 className={buildUploadStyles.uploadBuildError}>{`Upload Error:\n${uploadBuildError}`}</h3>
        }
        return null
    }


    renderForm = (): React.ReactElement => {
        const { newBuild, clearBuildUploadMessage } = this.state
        const { products, chipTypes } = this.props

        let idCounter = 0
        return (
            <form
                className={buildUploadStyles.form}
                onSubmit={this.handleBuildSubmit}
            >
                <h1 className={buildUploadStyles.header}>Build Upload</h1>

                <label className={buildUploadStyles.formComponent} htmlFor="product">Product:</label> {/* eslint-disable-line*/}
                <select className={buildUploadStyles.formComponent} name="product" value={newBuild.product} onChange={(e): any => this.handleChange(e, 'newBuild')}>
                    {
                        Object.entries(products).map(([, value]: any): React.ReactNode | null => {
                            idCounter += 1
                            if (value.sku) {
                                return (
                                    <option key={idCounter} value={value.sku}>{value.sku}</option>
                                )
                            }
                            return null
                        })
                    }
                </select>

                {this.generateTextInput('build_number', '1234567890', 'newBuild')}

                {this.generateTextInput('version', '1.2.3', 'newBuild')}

                <label className={buildUploadStyles.formComponent} htmlFor="chip">Chip:</label> {/* eslint-disable-line*/}
                <select className={buildUploadStyles.formComponent} name="chip" value={newBuild.chip} onChange={(e): any => this.handleChange(e, 'newBuild')}>
                    {
                        chipTypes.map((value: string): React.ReactNode | null => {
                            idCounter += 1
                            return <option key={idCounter} value={value}>{value}</option>
                        })
                    }
                </select>

                <label className={buildUploadStyles.formComponent} htmlFor="firmware">Firmware:</label> {/* eslint-disable-line*/}
                <input className={buildUploadStyles.formComponent} name="firmware" type="file" onChange={(e): any => this.handleChange(e, 'newBuild')} ref={(ref): any => this.fileInputRef = ref} /> {/* eslint-disable-line*/}

                <label className={buildUploadStyles.formComponent} htmlFor='released'>Released:</label> {/* eslint-disable-line*/}
                <input
                    className={`${buildUploadStyles.formComponent} ${buildUploadStyles.releasedCheckbox}`}
                    type="checkbox"
                    name="released"
                    checked={newBuild.released}
                    onChange={(e): any => this.handleChange(e, 'newBuild')}
                />

                {this.generatePbuldCheckboxes()}
                {this.generateRevisionCheckboxes()}

                <input className={buildUploadStyles.submitButton} type="submit" />

                {this.renderUploadSuccessOrError(clearBuildUploadMessage)}
            </form>
        )
    }


    render(): React.ReactNode {
        return this.renderForm()
    }
}

const mapStateToProps = (state: any): object => (
    {
        pbuldList: state.pbuldList,
        getPBULDListError: state.getPBULDListError,
        products: state.products,
        chipTypes: state.chipTypes,
        getProductListError: state.getProductListError,
        revisionList: state.revisionList,
        getRevisionListError: state.getRevisionListError,
        uploadBuildError: state.uploadBuildError,
        uploadBuildSuccess: state.uploadBuildSuccess,
    }
)

const mapDispatchToProps = (dispatch: Function): object => (
    {
        getProductList: (): void => dispatch(getProductList()),
        getRevisionList: (): void => dispatch(getRevisionList()),
        getPbuldList: (): void => dispatch(getPbuldList()),
        getChipList: (): void => dispatch(getChipList()),
        uploadBuild: (newBuild: any): void => dispatch(uploadBuild(newBuild)),
    }
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BuildUploadForm)
