import React from 'react'
import { connect } from 'react-redux'
import {
    Route,
    RouteProps,
    Redirect,
} from 'react-router-dom'

const PrivateRoute: React.FC<LayoutProps> = ({ children, ...rest }: any) => (
    <Route
        {...rest}
        render={(): React.ReactNode => (
            rest.loggedIn ? (
                children
            ) : (
                <Redirect to="/login" />
            )
        )}
    />
)

const mapStateToProps = (state: {loggedIn: boolean}): any => (
    { loggedIn: state.loggedIn }
)

export default connect(
    mapStateToProps,
    null,
)(PrivateRoute)

interface LayoutProps {
    children: RouteProps['children']
    path: RouteProps['path']
}
