import React, { Component } from 'react'
import { connect } from 'react-redux'
import { boardSearch } from '../actions/index'
import formStyles from '../styles/form.module.css'
import sectionStyles from '../styles/section.module.css'
import boardSearchStyles from '../styles/boardSearch.module.css'

class BoardSearchForm extends Component<any, any> {
    state: ComponentState = {
        searchTerm: '',
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target
        this.setState({
            [name]: value,
        })
    }

    handleSubmit = (e: React.FormEvent<HTMLElement>): void => {
        const { boardSearch } = this.props

        e.preventDefault()
        boardSearch(this.state)
    }

    renderForm = (): React.ReactElement => {
        const { searchTerm } = this.state
        return (
            <React.Fragment>
                <form className={formStyles.form} onSubmit={this.handleSubmit}>
                    <h1 className={sectionStyles.header}>Board Search</h1>
                    <label className={formStyles.component} htmlFor="searchTerm">Search:</label> {/* eslint-disable-line*/}
                    <input
                        className={formStyles.component}
                        type="text"
                        name="searchTerm"
                        value={searchTerm}
                        onChange={this.handleChange}
                    />
                    <input
                        className={formStyles.submitButton}
                        value="Search"
                        type="submit"
                    />
                </form>
                {this.renderError()}
                {this.renderResults()}
            </React.Fragment>
        )
    }

    renderError = (): React.ReactElement | null => {
        const { boardSearchError } = this.props

        if (boardSearchError) {
            const errorMessage = boardSearchError
            return (
                <div className={formStyles.error}>
                    {`${errorMessage}`}
                </div>
            )
        }
        return null
    }

    renderTestResults = (key: any, val: any) => {
        let idCounter = 0
        return (
            <>
                <div key={`${key}-${idCounter}`}>
                    <span className={boardSearchStyles.resultItemLabel}>{`${key}:`}</span>
                    <br />
                    {Object.entries(val).map(([k, v]: any) => {
                        idCounter += 1
                        return (
                            <div className={boardSearchStyles.resultItemContainer} key={`${key}-${idCounter}`}>
                                <span className={boardSearchStyles.resultItemLabel}>{`${k}:`}</span>
                                <br />
                                {this.highlightResult(v)}
                            </div>
                        )
                    })}
                </div>
                <br />
            </>
        )
    }

    highlightResult = (val: string): React.ReactElement | null => {
        switch (val) {
        case 'Pass':
            return <span className={boardSearchStyles.success}>{`${val}`}</span>
        case 'Fail':
        case 'Error':
        case 'Abort':
        case 'Cancel':
            return <span className={boardSearchStyles.error}>{`${val}`}</span>
        default:
            return <span>{`${val}`}</span>
        }
    }

    renderResults = (): React.ReactElement | null => {
        const { boardResult } = this.props
        if (boardResult === null || boardResult === undefined) {
            return null
        }
        if (Object.keys(boardResult).length !== 0) {
            let columnCounter = 0

            return (
                <div className={boardSearchStyles.resultContainer}>
                    {
                        Object.entries(boardResult).map(([key, value]: any) => {
                            key = key.charAt(0).toUpperCase() + key.slice(1)

                            let idCounter = 0
                            columnCounter += 1

                            if (Object.keys(value).length === 0) {
                                return null
                            }

                            return (
                                <div
                                    className={boardSearchStyles.resultSubContainer}
                                    style={{ gridColumnStart: `${columnCounter}` }}
                                    key={`${key}-container`}
                                >
                                    <h2 key={key}>
                                        {`${key}`}
                                    </h2>
                                    {
                                        Object.entries(value).map(([k, val]: any) => {
                                            idCounter += 1
                                            if (k.includes('Result')) {
                                                return this.renderTestResults(k, val)
                                            }
                                            return (
                                                <div className={boardSearchStyles.resultItemContainer} key={idCounter}>
                                                    <span className={boardSearchStyles.resultItemLabel}>{`${k}:`}</span>
                                                    <br />
                                                    <span>{`${val}`}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        return null
    }

    render(): React.ReactNode {
        return this.renderForm()
    }
}

const mapStateToProps = (state: BoardSearchFormInterface): object => (
    {
        boardResult: state.boardResult,
        boardSearchError: state.boardSearchError,
    }
)

const mapDispatchToProps = (dispatch: Function): object => (
    {
        boardSearch: (state: {searchTerm: string}): void => dispatch(boardSearch(state)),
    }
)

interface BoardSearchFormInterface {
    boardResult: object
    boardSearchError: string
}

interface ComponentState {
    searchTerm: string
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BoardSearchForm)
