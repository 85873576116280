import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../actions/index'
import headerStyles from '../../styles/header.module.css'

// TODO: Consolidate interfaces.

const Header: any = (props: Props): React.ReactElement => {

    const logoutHandler = (
        e: React.MouseEvent<HTMLElement>,
    ): any => {
        e.preventDefault()
        return props.logout()
    }

    const { loggedIn } = props

    return (
        <div className={headerStyles.container}>
            <Link to="/dashboard">
                <img className={headerStyles.image} src="/acroname_logo_white.svg" alt="Acroname logo" />
            </Link>
            {loggedIn
                ? (
                    <button
                        className={headerStyles.logoutButton}
                        onClick={logoutHandler}
                        type="button"
                    >
                        Logout
                    </button>
                )
                : null}
        </div>
    )
}

const mapStateToProps = (state: {loggedIn: boolean}): any => (
    { loggedIn: state.loggedIn }
)

const mapDispatchToProps = (dispatch: Function): any => (
    { logout: (): any => dispatch(logout()) }
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header)

interface Props {
    loggedIn: boolean
    logout: Function
}
