import React, { Component } from 'react'
import { connect } from 'react-redux'
import { login } from '../actions/index'
import formStyles from '../styles/form.module.css'
import sectionStyles from '../styles/section.module.css'

export class LoginForm extends Component<any, any> {
    state: ComponentState = {
        username: '',
        password: '',
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>): any => {
        const { name, value } = e.target
        this.setState({
            [name]: value,
        })
    }

    handleSubmit = (e: React.FormEvent<HTMLElement>): any => {
        e.preventDefault()
        const { login } = this.props
        login(this.state)
        this.setState({
            username: '',
            password: '',
        })
    }

    render(): React.ReactNode {
        const { loginError } = this.props
        return (
            <React.Fragment>
                <form className={formStyles.form} onSubmit={this.handleSubmit}>
                    <h1 className={sectionStyles.header}>Login</h1>
                    <label className={formStyles.component} htmlFor='username:'>Username:</label> {/* eslint-disable-line*/}
                    <input
                        className={formStyles.component}
                        type="text"
                        name="username"
                        value={this.state.username}
                        onChange={this.handleChange}
                    />
                    <label className={formStyles.component} htmlFor="password:">Password:</label> {/* eslint-disable-line*/}
                    <input
                        className={formStyles.component}
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                    />
                    <input className={formStyles.submitButton} type="submit" />
                </form>
                {loginError
                    ? <div className={formStyles.error}>{`${loginError}`}</div>
                    : null}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: Props): any => (
    {
        loggedIn: state.loggedIn,
        loginError: state.loginError,
    }
)

const mapDispatchToProps = (dispatch: Function): any => (
    {
        login: (state: {username: string, password: string}): any => dispatch(login(state)),
    }
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginForm)


interface Props {
    login: Function
    loggedIn: boolean
    loginError: string
}

interface ComponentState {
    username: string
    password: string
}