import { parseString } from 'xml2js'

import axiosInstance from '../services/axiosInstance'
import {
    ACCESS_TOKEN_NAME,
    ADD_ENTITLEMENTS_ERROR,
    ADD_ENTITLEMENTS_SUCCESS,
    BOARDSEARCH_ERROR,
    BOARDSEARCH_SUCCESS,
    CLEAR_ADD_ENTITLEMENTS_SUCCESS,
    GET_CHIP_LIST_ERROR,
    GET_CHIP_LIST_SUCCESS,
    GET_CURRENT_ENTITLEMENTS_LIST_ERROR,
    GET_CURRENT_ENTITLEMENTS_LIST_SUCCESS,
    GET_ENTITLEMENTS_LIST_ERROR,
    GET_ENTITLEMENTS_LIST_SUCCESS,
    GET_PO_ERROR,
    GET_PO_SUCCESS,
    GET_PBULD_LIST_ERROR,
    GET_PBULD_LIST_SUCCESS,
    GET_PRODUCT_LIST_ERROR,
    GET_PRODUCT_LIST_SUCCESS,
    GET_REVISION_LIST_ERROR,
    GET_REVISION_LIST_SUCCESS,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    PREFETCH_PO_LIST_ERROR,
    PREFETCH_PO_LIST_SUCCESS,
    REFRESH_TOKEN_NAME,
    SAVE_LOT_NUMBER_ERROR,
    SAVE_LOT_NUMBER_SUCCESS,
    SET_IS_LOADING,
    UPLOAD_BUILD_SUCCESS,
    UPLOAD_BUILD_ERROR,
} from '../constants/action-types'

// TODO: Write action creator.
// TODO: Consolidate interfaces.

const sanitizeSearchTerm = (data: string): string => {
    let searchTerm = data.trim()
    if (searchTerm.length >= 32) {
    // if (searchTerm.length > 13) {
        searchTerm = searchTerm.replace(/\s/g, '')
        let searchTermArr = null
        searchTermArr = searchTerm.match(/.{1,8}/g)
        if (searchTermArr !== null) {
            searchTerm = searchTermArr.join(' ')
        }
    }
    return searchTerm.toUpperCase()
}

/* <----- Login/Logout -----> */
export function login(credentials: { username: string, password: string }) {
    return async (dispatch: Function): Promise<any> => {
        let res = {
            data: {
                refresh: '',
                access: '',
            },
        }
        try {
            res = await axiosInstance.post(
                '/api/token/',
                credentials,
            )
        } catch (error) {
            return dispatch({ type: LOGIN_ERROR, payload: 'Username or password incorrect or does not exist.' })
        }

        const { access: accessToken, refresh: refreshToken } = res.data
        localStorage.setItem(ACCESS_TOKEN_NAME, accessToken)
        localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken)
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        return dispatch({ type: LOGIN_SUCCESS })
    }
}

export function loginWithValidStoredCreds(): Function {
    return (dispatch: Function): any => (
        dispatch({ type: LOGIN_SUCCESS })
    )
}

export function logout(message = ''): Function {
    return (dispatch: Function): any => {
        localStorage.removeItem(ACCESS_TOKEN_NAME)
        localStorage.removeItem(REFRESH_TOKEN_NAME)
        return dispatch({ type: LOGOUT, payload: message })
    }
}

/* <----- Boardsearch -----> */
export function boardSearch(data: {searchTerm: string}) {
    return async (dispatch: Function): Promise<any> => {
        let res = {
            data: {},
        }

        const searchTerm = sanitizeSearchTerm(data.searchTerm)

        try {
            res = await axiosInstance.get(
                `/boardsearch?data=${searchTerm}`,
            )
        } catch (error) {
            const errorMessage = error.response.data.detail
            const usefulErrorMessage = errorMessage.split('\n')[0]
            return dispatch({ type: BOARDSEARCH_ERROR, payload: usefulErrorMessage })
        }

        const boardRecord = res.data
        return dispatch({ type: BOARDSEARCH_SUCCESS, payload: boardRecord })
    }
}

/* <----- Entitlements -----> */
export function getEntitlementsList(searchTerm: string): Function {
    return async (dispatch: Function): Promise<any> => {
        let res = {
            data: {
                results: [],
            },
        }

        const boardIdentifier = sanitizeSearchTerm(searchTerm)

        try {
            res = await axiosInstance.get(
                `/productentitlements?board_identifier=${boardIdentifier}`,
            )
        } catch (error) {
            let errorMessage = 'Unknown error - try again.'
            if (error.response.data) {
                errorMessage = error.response.data
            }
            return dispatch({ type: GET_ENTITLEMENTS_LIST_ERROR, payload: errorMessage })
        }

        const entitlements: any = res.data
        return dispatch({ type: GET_ENTITLEMENTS_LIST_SUCCESS, payload: entitlements })
    }
}

export function clearAddEntitlementsSuccess(): Function {
    return async (dispatch: Function): Promise<any> => (
        dispatch({ type: CLEAR_ADD_ENTITLEMENTS_SUCCESS })
    )
}

export function getCurrentEntitlementsList(searchTerm: string): Function {
    return async (dispatch: Function): Promise<any> => {
        let res: any = {
            data: {
                results: [],
            },
            status: '',
        }

        const boardIdentifier = sanitizeSearchTerm(searchTerm)

        try {
            res = await axiosInstance.get(
                `/boardlicense?board_identifier=${boardIdentifier}`,
            )
        } catch (error) {
            let errorMessage = 'Unknown error - try again.'
            if (error.response.data) {
                errorMessage = error.response.data
            }
            return dispatch({ type: GET_CURRENT_ENTITLEMENTS_LIST_ERROR, payload: errorMessage })
        }
        const { data } = res
        return dispatch({ type: GET_CURRENT_ENTITLEMENTS_LIST_SUCCESS, payload: data })
    }
}

export function addEntitlements(data: {searchTerm: string, selectedEntitlements: string[]}): Function {
    return async (dispatch: Function): Promise<any> => {
        let res: any = {
            data: {
                results: [],
            },
            status: '',
        }

        const searchTerm = sanitizeSearchTerm(data.searchTerm)

        const formData = new FormData()
        formData.append('board_identifier', searchTerm)
        formData.append('entitlements', JSON.stringify(data.selectedEntitlements))

        try {
            res = await axiosInstance.put(
                '/boardlicense',
                formData,
            )
        } catch (error) {
            let errorMessage = 'Unknown error - try again.'
            if (error.response.data) {
                errorMessage = error.response.data
            }
            return dispatch({ type: ADD_ENTITLEMENTS_ERROR, payload: errorMessage })
        }
        const { status } = res
        return dispatch({ type: ADD_ENTITLEMENTS_SUCCESS, payload: status })
    }
}

/* <----- Build Upload -----> */
export function getPbuldList(): Function {
    return async (dispatch: Function): Promise<any> => {
        let res = {
            data: {
                results: [],
            },
        }

        try {
            res = await axiosInstance.get(
                '/pbulds/',
            )
        } catch (error) {
            const errorMessage = error.response.data.message
            try {
                const usefulErrorMessage = errorMessage.split('\n')[0]
                return dispatch({ type: GET_PBULD_LIST_ERROR, payload: usefulErrorMessage })
            } catch {
                return dispatch({ type: GET_PBULD_LIST_ERROR, payload: error })
            }
        }
        try {
            const pbulds: any = res.data.results
            return dispatch({ type: GET_PBULD_LIST_SUCCESS, payload: pbulds })
        } catch (error) {
            console.error(error)
            return dispatch({ type: GET_PBULD_LIST_ERROR, payload: error })
        }
    }
}

export function getProductList(): Function {
    return async (dispatch: Function): Promise<any> => {
        let res = {
            data: {
                results: [],
            },
        }

        try {
            res = await axiosInstance.get(
                '/products/',
            )
        } catch (error) {
            const errorMessage = error.response.data.message
            try {
                const usefulErrorMessage = errorMessage.split('\n')[0]
                return dispatch({ type: GET_PRODUCT_LIST_ERROR, payload: usefulErrorMessage })
            } catch {
                return dispatch({ type: GET_PRODUCT_LIST_ERROR, payload: error })
            }
        }

        try {
            const products: any = res.data.results
            return dispatch({ type: GET_PRODUCT_LIST_SUCCESS, payload: products })
        } catch (error) {
            console.error(error)
            return dispatch({ type: GET_PRODUCT_LIST_ERROR, payload: error })
        }
    }
}

export function getChipList(): Function {
    return async (dispatch: Function): Promise<any> => {
        let res = {
            data: [],
        }

        try {
            res = await axiosInstance.get(
                '/chiptypes',
            )
        } catch (error) {
            const errorMessage = error.response.data.message
            try {
                const usefulErrorMessage = errorMessage.split('\n')[0]
                return dispatch({ type: GET_CHIP_LIST_ERROR, payload: usefulErrorMessage })
            } catch {
                return dispatch({ type: GET_CHIP_LIST_ERROR, payload: error })
            }
        }

        try {
            const chipTypes: any = res.data
            return dispatch({ type: GET_CHIP_LIST_SUCCESS, payload: chipTypes })
        } catch (error) {
            console.error(error)
            return dispatch({ type: GET_CHIP_LIST_ERROR, payload: error })
        }
    }
}

export function getRevisionList(): Function {
    return async (dispatch: Function): Promise<any> => {
        let res = {
            data: {
                results: [],
            },
        }

        try {
            res = await axiosInstance.get(
                '/revisions/',
            )
        } catch (error) {
            const errorMessage = error.response.data.message
            try {
                const usefulErrorMessage = errorMessage.split('\n')[0]
                return dispatch({ type: GET_REVISION_LIST_ERROR, payload: usefulErrorMessage })
            } catch {
                return dispatch({ type: GET_REVISION_LIST_ERROR, payload: error })
            }
        }
        try {
            const revisions: any = res.data.results
            return dispatch({ type: GET_REVISION_LIST_SUCCESS, payload: revisions })
        } catch (error) {
            return dispatch({ type: GET_REVISION_LIST_ERROR, payload: error })
        }
    }
}

export function uploadBuild(newBuild: any): Function {
    return async (dispatch: Function): Promise<any> => {
        let res: any = {
            data: {
                results: [],
            },
            status: '',
        }

        const formData = new FormData()
        for (const key in newBuild) { {/* eslint-disable-line*/}
            formData.append(key, newBuild[key])
        }

        try {
            res = await axiosInstance.post(
                '/builds/upload/',
                formData,
            )
        } catch (error) {
            let errorMessage = 'Unknown error - try again.'
            if (error.response.data.message) {
                [errorMessage] = error.response.data.message.split('\n')
            }
            return dispatch({ type: UPLOAD_BUILD_ERROR, payload: errorMessage })
        }
        const { status } = res
        return dispatch({ type: UPLOAD_BUILD_SUCCESS, payload: status })
    }
}

/* <----- Lot Number -----> */
export function prefetchAligniPOList(): Function {
    return async (dispatch: Function): Promise<any> => {
        dispatch({ type: SET_IS_LOADING, payload: true })
        let res: any
        let xml: any
        let json: any

        try {
            res = await axiosInstance.get(
                '/oj1/api/rasp_proxy/purchase_orders',
            )
            xml = res.data
        } catch (error) {
            console.error(error)
            // let errorMessage = 'Unknown error - try again.'
            let errorMessage = error;
            let errorDetail = ''
            if (error.response.hasOwnProperty('data')) {
                errorDetail = error.response?.data ?? ''
                if (errorDetail) {
                    try {
                        parseString(errorDetail, (err, result) => {
                            if (err) console.error(err)
                            else errorMessage += `\n${result.error.message}`
                        })
                    } catch (errorMore) {
                        dispatch({ type: SET_IS_LOADING, payload: false })
                        return dispatch({ type: PREFETCH_PO_LIST_ERROR, payload: errorMore })
                    }
                }
            }
            dispatch({ type: SET_IS_LOADING, payload: false })
            return dispatch({ type: PREFETCH_PO_LIST_ERROR, payload: errorMessage })
        }
        parseString(xml, (err, result) => {
            if (err) console.error(err)
            json = result
        })
        try {
            const pos = json.purchase_orders.purchase_order
            const data = []
            for (let value of Object.values(pos)) { {/* eslint-disable-line*/}
                const { number, id } = Object(value)
                data.push({ [number]: id[0] })
            }
            dispatch({ type: SET_IS_LOADING, payload: false })
            return dispatch({ type: PREFETCH_PO_LIST_SUCCESS, payload: data })
        } catch (error) {
            console.error(error)
            dispatch({ type: SET_IS_LOADING, payload: false })
            return dispatch({ type: PREFETCH_PO_LIST_ERROR, payload: error })
        }
    }
}

export function getAligniPOInfo(poID: string): Function {
    return async (dispatch: Function): Promise<any> => {
        dispatch({ type: SET_IS_LOADING, payload: true })
        let res: any
        let xml: any
        let json: any

        if (!poID) {
            dispatch({ type: SET_IS_LOADING, payload: false })
            return dispatch({
                type: GET_PO_ERROR,
                payload: 'Entered PO number does not exist.',
            })
        }

        try {
            res = await axiosInstance.get(
                '/oj1/api/rasp_proxy/purchase_order',
                {
                    params: {
                        po_id: poID,
                    },
                },
            )
            xml = res.data
        } catch (error) {
            console.log(error)
            let errorMessage = 'Unknown error - try again.'
            if (error.response.hasOwnProperty('data')) {
                errorMessage = error.response.data
                if (errorMessage.hasOwnProperty('detail')) {
                    errorMessage = error.response.data.detail
                }
            }
            dispatch({ type: SET_IS_LOADING, payload: false })
            return dispatch({ type: GET_PO_ERROR, payload: errorMessage })
        }
        parseString(xml, (err, result) => {
            if (err) console.log(err)
            json = result
        })

        const poInner = json.purchase_order
        const lot_number = poInner.number[0]
        const issue_date = poInner.created_at[0]
        const manufacturer = poInner.vendor[0].name[0]

        let part_number: any
        let quantity_issued = 0
        let previous_part_number = ''

        for (let item of poInner.purchase_items[0].purchase_item) { {/* eslint-disable-line*/}
            try {
                part_number = item.partnumber[0]

                if (part_number && previous_part_number && part_number !== previous_part_number) {
                    dispatch({ type: SET_IS_LOADING, payload: false })
                    return dispatch({
                        type: GET_PO_ERROR,
                        payload: `PO '${lot_number}' found but contains multiple part types: '${part_number}' and '${previous_part_number} -\nmust be only one type of PBULD* or PMNFG*.\nPlease correct in Aligni.`,
                    })
                }

                if (part_number && (part_number.includes('PBULD') || part_number.includes('PMNFG') || part_number.includes('R'))) {
                    quantity_issued += Number(item.quantity[0])
                    previous_part_number = part_number
                }
            } catch (error) {
                console.error(`Error: ${error} for PO# ${lot_number} for item:`, item)
                continue
            }
        }
        let pbuld = part_number.replace('PMNFG', 'PBULD')
        if (pbuld.startsWith('R')) {
            pbuld = ''
        } else if (!pbuld.includes('PBULD')) {
            dispatch({ type: SET_IS_LOADING, payload: false })
            return dispatch({
                type: GET_PO_ERROR,
                payload: `PO '${lot_number}' found but contains part type '${pbuld}' -\nmust be one of PBULD* or PMNFG*.\nPlease search again.`,
            })
        }
        const data = {
            manufacturer, pbuld, lot_number, issue_date, quantity_issued,
        }
        dispatch({ type: SET_IS_LOADING, payload: false })
        return dispatch({ type: GET_PO_SUCCESS, payload: data })
    }
}

export function saveLotNumber(purchaseOrder: any): Function {
    return async (dispatch: Function): Promise<any> => {
        let res: any
        const POCopy = { ...purchaseOrder }

        // Date format changes needed for Safari; this work in both Chromium and Safari.
        const issue_date = POCopy.issue_date.replace(' ', 'T').replace(' ', '')
        const dt = new Date(issue_date)

        const issueDateUTC = `${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}-${dt.getUTCDate()}`

        // Add 1 to  getUTCMonth above because this function returns a zero-indexed number
        POCopy.issue_date = issueDateUTC

        const { lot_number } = POCopy
        try {
            res = await axiosInstance.get(
                '/lots/',
                {
                    params: {
                        lot_number,
                    },
                },
            )
        } catch (error) {
            console.log(error)
            let errorMessage = 'Unknown error - try again.'
            if (error.response.hasOwnProperty('data')) {
                errorMessage = error.response.data
                if (errorMessage.hasOwnProperty('detail')) {
                    errorMessage = error.response.data.detail
                } else {
                    console.error(errorMessage)
                    let newErrorMessage = ''
                    for (let [key, values] of Object.entries(errorMessage)) { {/* eslint-disable-line*/}
                        for (let value of Object.values(values)) { {/* eslint-disable-line*/}
                            newErrorMessage += `${key}: ${value}\n`
                        }
                    }
                    errorMessage = newErrorMessage
                }
            }
            if (errorMessage === `Lot number '${lot_number}' does not exist.`) {
                try {
                    await axiosInstance.post(
                        '/lots/',
                        POCopy,
                    )
                } catch (createError) {
                    console.log(createError)
                    if (createError.response.hasOwnProperty('data')) {
                        errorMessage = createError.response.data
                        if (errorMessage.hasOwnProperty('detail')) {
                            errorMessage = createError.response.data.detail
                        } else {
                            console.error(errorMessage)
                            let newErrorMessage = ''
                            for (let [key, values] of Object.entries(errorMessage)) { {/* eslint-disable-line*/}
                                for (let value of Object.values(values)) { {/* eslint-disable-line*/}
                                    newErrorMessage += `${key}: ${value}\n`
                                }
                            }
                            errorMessage = newErrorMessage
                        }
                    }
                    return dispatch({ type: SAVE_LOT_NUMBER_ERROR, payload: errorMessage })
                }
                return dispatch({ type: SAVE_LOT_NUMBER_SUCCESS })
            }
            return dispatch({ type: SAVE_LOT_NUMBER_ERROR, payload: errorMessage })
        }

        const lotID = res.data.id
        POCopy.pbuld = res.data.pbuld
        POCopy.manufacturer = res.data.manufacturer

        try {
            await axiosInstance.put(
                `/lots/${lotID}/`,
                POCopy,
            )
        } catch (error) {
            console.log(error)
            let errorMessage = 'Unknown error - try again.'
            if (error.response.hasOwnProperty('data')) {
                errorMessage = error.response.data
                if (errorMessage.hasOwnProperty('detail')) {
                    errorMessage = error.response.data.detail
                } else {
                    console.error(errorMessage)
                    let newErrorMessage = ''
                    for (let [key, values] of Object.entries(errorMessage)) { {/* eslint-disable-line*/}
                        for (let value of Object.values(values)) { {/* eslint-disable-line*/}
                            newErrorMessage += `${key}: ${value}\n`
                        }
                    }
                    errorMessage = newErrorMessage
                }
            }
            return dispatch({ type: SAVE_LOT_NUMBER_ERROR, payload: errorMessage })
        }
        return dispatch({ type: SAVE_LOT_NUMBER_SUCCESS })
    }
}
