import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import Header from '../components/shared/Header'
import LoginForm from '../components/LoginForm'

const Login = (props: any): any => {
    const { loggedIn, history } = props
    if (loggedIn) {
        history.push('/dashboard')
        return <Redirect to="/dashboard" />
    } return (
        <div>
            <Header />
            <LoginForm />
        </div>
    )
}

const mapStateToProps = (state: { loggedIn: boolean }): any => (
    { loggedIn: state.loggedIn }
)

export default withRouter(connect(
    mapStateToProps,
    null,
)(Login))
