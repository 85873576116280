import React from 'react';
import spinnerStyles from '../../styles/spinner.module.css'

export default function LoadingSpinner() {
    return (
        <div className={spinnerStyles.spinnerContainer}>
            <div className={spinnerStyles.loadingSpinner} />
        </div>
    );
}
