// eslint-disable-next-line
import { config } from 'dotenv'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import initInterceptors from './services/initInterceptors'
import store from './store/index'
import App from './App'

initInterceptors(store)

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
)
