import React from 'react'
import { connect } from 'react-redux'
import Header from '../components/shared/Header'
import BuildUploadForm from '../components/BuildUploadForm'

const BuildUploadPage = (): any => (
    <div>
        <Header />
        <BuildUploadForm />
    </div>
)

export default connect(
    null,
    null,
)(BuildUploadPage)
