import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Header from '../components/shared/Header'
import sectionStyles from '../styles/section.module.css'
import linkStyles from '../styles/link.module.css'

const Dashboard = (): any => (
    <React.Fragment>
        <Header />
        <h1 className={sectionStyles.sectionHeader}>Dashboard</h1>
        <div className={linkStyles.linkContainer}>
            <Link className={linkStyles.link} to="/boardsearch">Board Search</Link>
            <Link className={linkStyles.link} to="/buildupload">Build Upload </Link>
            <Link className={linkStyles.link} to="/entitlements">Entitlements</Link>
            <Link className={linkStyles.link} to="/lotnumber">Lot Number</Link>
        </div>
    </React.Fragment>
)

export default connect(
    null,
    null,
)(Dashboard)
