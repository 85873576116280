import {
    ADD_ENTITLEMENTS_ERROR,
    ADD_ENTITLEMENTS_SUCCESS,
    BOARDSEARCH_ERROR,
    BOARDSEARCH_SUCCESS,
    CLEAR_ADD_ENTITLEMENTS_SUCCESS,
    GET_CHIP_LIST_ERROR,
    GET_CHIP_LIST_SUCCESS,
    GET_CURRENT_ENTITLEMENTS_LIST_ERROR,
    GET_CURRENT_ENTITLEMENTS_LIST_SUCCESS,
    GET_ENTITLEMENTS_LIST_ERROR,
    GET_ENTITLEMENTS_LIST_SUCCESS,
    GET_PO_ERROR,
    GET_PO_SUCCESS,
    GET_PBULD_LIST_ERROR,
    GET_PBULD_LIST_SUCCESS,
    GET_PRODUCT_LIST_ERROR,
    GET_PRODUCT_LIST_SUCCESS,
    GET_REVISION_LIST_ERROR,
    GET_REVISION_LIST_SUCCESS,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    PREFETCH_PO_LIST_ERROR,
    PREFETCH_PO_LIST_SUCCESS,
    SAVE_LOT_NUMBER_ERROR,
    SAVE_LOT_NUMBER_SUCCESS,
    SET_IS_LOADING,
    UPLOAD_BUILD_ERROR,
    UPLOAD_BUILD_SUCCESS,
} from '../constants/action-types'

// TODO: Consider a function that generates a reducer,
// or at least pull all reducers out in their own file.

// TODO: Consolidate interfaces.

interface StateShape {
    addEntitlementsError: string
    addEntitlementsSuccess: string
    boardResult: any
    boardSearchError: string
    chipTypes: string[]
    entitlements: object[]
    getEntitlementsListError: string
    getCurrentEntitlementsListError: string
    currentEntitlements: string[]
    getPOError: string,
    getProductListError: string
    getRevisionListError: string
    isLoading: boolean
    loggedIn: boolean
    loginError: string
    products: object[]
    pbuldList: object[]
    revisionList: object[]
    prefetchPOError: string
    purchaseOrder: object,
    purchaseOrders: object[]
    uploadBuildError: string
    uploadBuildSuccess: string,
}

const initialState = {
    addEntitlementsError: '',
    addEntitlementsSuccess: '',
    boardResult: {},
    boardSearchError: '',
    chipTypes: [],
    currentEntitlements: [],
    entitlements: [],
    getCurrentEntitlementsListError: '',
    getEntitlementsListError: '',
    getProductListError: '',
    getPOError: '',
    getRevisionListError: '',
    isLoading: false,
    loggedIn: false,
    loginError: '',
    pbuldList: [],
    products: [],
    prefetchPOError: '',
    purchaseOrder: {},
    purchaseOrders: [],
    revisionList: [],
    uploadBuildError: '',
    uploadBuildSuccess: '',
}

function rootReducer(state: StateShape = initialState, action: any): any {
    // Add Entitlement
    if (action.type === ADD_ENTITLEMENTS_ERROR) {
        return {
            ...state,
            addEntitlementsError: action.payload,
            addEntitlementsSuccess: '',
        }
    }
    if (action.type === ADD_ENTITLEMENTS_SUCCESS) {
        return {
            ...state,
            addEntitlementsError: '',
            addEntitlementsSuccess: action.payload,
        }
    }
    // Board Search
    if (action.type === BOARDSEARCH_ERROR) {
        return {
            ...state,
            boardSearchError: action.payload,
            boardResult: {},
        }
    }
    if (action.type === BOARDSEARCH_SUCCESS) {
        return {
            ...state,
            boardResult: action.payload,
            boardSearchError: '',
        }
    }
    // Chip Types
    if (action.type === GET_CHIP_LIST_ERROR) {
        return {
            ...state,
            getChipListError: action.payload,
            chipTypes: [],
        }
    }
    if (action.type === GET_CHIP_LIST_SUCCESS) {
        return {
            ...state,
            chipTypes: action.payload,
            getChipListError: '',
        }
    }
    // Get Current Entitlements
    if (action.type === GET_CURRENT_ENTITLEMENTS_LIST_ERROR) {
        return {
            ...state,
            currentEntitlements: [],
            getCurrentEntitlementsListError: action.payload,
            getCurrentEntitlementsListSuccess: '',
        }
    }
    if (action.type === GET_CURRENT_ENTITLEMENTS_LIST_SUCCESS) {
        return {
            ...state,
            currentEntitlements: action.payload,
            getCurrentEntitlementsListError: '',
            getCurrentEntitlementsListSuccess: true,
        }
    }
    // Entitlements
    if (action.type === GET_ENTITLEMENTS_LIST_ERROR) {
        return {
            ...state,
            getEntitlementsListError: action.payload,
            entitlements: [],
        }
    }
    if (action.type === GET_ENTITLEMENTS_LIST_SUCCESS) {
        return {
            ...state,
            entitlements: action.payload,
            getEntitlementsListError: '',
        }
    }
    if (action.type === CLEAR_ADD_ENTITLEMENTS_SUCCESS) {
        return {
            ...state,
            addEntitlementsSuccess: '',
        }
    }
    // PBULD
    if (action.type === GET_PBULD_LIST_ERROR) {
        return {
            ...state,
            getPbuldListError: action.payload,
            pbuldList: [],
        }
    }
    if (action.type === GET_PBULD_LIST_SUCCESS) {
        return {
            ...state,
            pbuldList: action.payload,
            getPbuldListError: '',
        }
    }
    // Product
    if (action.type === GET_PRODUCT_LIST_ERROR) {
        return {
            ...state,
            getProductListError: action.payload,
            products: [],
        }
    }
    if (action.type === GET_PRODUCT_LIST_SUCCESS) {
        return {
            ...state,
            products: action.payload,
            getProductListError: '',
        }
    }
    // Revision
    if (action.type === GET_REVISION_LIST_ERROR) {
        return {
            ...state,
            getRevisionListError: action.payload,
            revisionList: [],
        }
    }
    if (action.type === GET_REVISION_LIST_SUCCESS) {
        return {
            ...state,
            revisionList: action.payload,
            getRevisionListError: '',
        }
    }
    // Login
    if (action.type === LOGIN_ERROR) {
        return {
            ...state,
            loginError: action.payload,
        }
    }
    if (action.type === LOGIN_SUCCESS) {
        return {
            ...state,
            loggedIn: true,
            loginError: '',
        }
    }
    if (action.type === LOGOUT) {
        return {
            ...initialState,
            loginError: action.payload,
        }
    }
    if (action.type === SET_IS_LOADING) {
        return {
            ...state,
            isLoading: action.payload
        }
    }

    // Prefetch Aligni PO's
    if (action.type === PREFETCH_PO_LIST_ERROR) {
        return {
            ...state,
            prefetchPOError: action.payload,
            purchaseOrders: [],
        }
    }
    if (action.type === PREFETCH_PO_LIST_SUCCESS) {
        return {
            ...state,
            purchaseOrders: action.payload,
            prefetchPOError: '',
        }
    }
    // Get Specified Purchase Order
    if (action.type === GET_PO_ERROR) {
        return {
            ...state,
            getPurchaseOrderError: action.payload,
            purchaseOrder: {},
        }
    }
    if (action.type === GET_PO_SUCCESS) {
        return {
            ...state,
            purchaseOrder: action.payload,
            getPurchaseOrderError: '',
            saveLotNumberError: '',
            saveLotNumberSuccess: '',
        }
    }
    // Save Lot Number to Database
    if (action.type === SAVE_LOT_NUMBER_ERROR) {
        return {
            ...state,
            saveLotNumberError: action.payload,
            saveLotNumberSuccess: '',
        }
    }
    if (action.type === SAVE_LOT_NUMBER_SUCCESS) {
        return {
            ...state,
            saveLotNumberError: '',
            saveLotNumberSuccess: 'Succesfully saved',
        }
    }
    // Upload build
    if (action.type === UPLOAD_BUILD_ERROR) {
        return {
            ...state,
            uploadBuildError: action.payload,
            uploadBuildSuccess: '',
        }
    }
    if (action.type === UPLOAD_BUILD_SUCCESS) {
        return {
            ...state,
            uploadBuildError: '',
            uploadBuildSuccess: action.payload,
        }
    }
    return state
}

export default rootReducer
