import React from 'react'
import { connect } from 'react-redux'
import Header from '../components/shared/Header'
import BoardSearchForm from '../components/BoardSearchForm'

const BoardSearch = (): any => (
    <div>
        <Header />
        <BoardSearchForm />
    </div>
)

export default connect(
    null,
    null,
)(BoardSearch)
