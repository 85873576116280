import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    prefetchAligniPOList,
    getAligniPOInfo,
    saveLotNumber,
} from '../actions/index'
import addEntitlementsStyles from '../styles/addEntitlements.module.css'
import sectionStyles from '../styles/section.module.css'
import LoadingSpinner from './shared/LoadingSpinner'

class LotNumberForm extends Component<any, any> {
    state: ComponentState = {
        searchTerm: '',
    }

    /* <----- Lifecycle methods -----> */
    async componentDidMount(): Promise<any> {
        const { prefetchAligniPOList } = this.props
        await prefetchAligniPOList()
    }

    /* <----- Form change handlers -----> */
    handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target
        this.setState({
            [name]: value,
        })
    }

    handleSearch = (e: React.FormEvent<HTMLElement>): void => {
        e.preventDefault()

        const { searchTerm } = this.state
        const {
            getAligniPOInfo,
            purchaseOrders,
        } = this.props

        let poID = ''
        purchaseOrders.forEach((element: any) => {
            if (element[searchTerm]) poID = element[searchTerm]
        })
        getAligniPOInfo(poID)
    }

    handleSubmit = (e: React.FormEvent<HTMLElement>): void => {
        e.preventDefault()
        const {
            purchaseOrder,
            saveLotNumber,
        } = this.props
        saveLotNumber(purchaseOrder)
    }

    /* <----- Error Renders -----> */
    renderSearchError = (): React.ReactNode | null => {
        const { getPurchaseOrderError, prefetchPOError } = this.props

        if (prefetchPOError) {
            return <h3 className={addEntitlementsStyles.error}>{prefetchPOError}</h3>
        }
        if (getPurchaseOrderError) {
            return <h3 className={addEntitlementsStyles.error}>{getPurchaseOrderError}</h3>
        }
        return null
    }

    renderSaveSuccessOrError = (): React.ReactNode | null => {
        const { saveLotNumberError, saveLotNumberSuccess } = this.props

        if (saveLotNumberSuccess) {
            return <h3 className={addEntitlementsStyles.success}>{saveLotNumberSuccess}</h3>
        } if (saveLotNumberError) {
            return <h3 className={addEntitlementsStyles.error}>{`Error:\n${saveLotNumberError}`}</h3>
        }
        return null
    }

    /* <----- Search form components -----> */
    renderSearchForm = (): React.ReactNode => (
        <React.Fragment>
            <form className={addEntitlementsStyles.form} onSubmit={this.handleSearch}>
                <h1 className={sectionStyles.header}>Lot Number</h1>
                <label  className={addEntitlementsStyles.componentLabel} htmlFor="searchTerm"> {/* eslint-disable-line*/}
                    {'PO/Lot Number:\n'}
                </label>
                <input
                    className={addEntitlementsStyles.component}
                    type="text"
                    name="searchTerm"
                    // eslint-disable-next-line react/destructuring-assignment
                    value={this.state.searchTerm}
                    onChange={this.handleChange}
                />
                <input
                    className={`${addEntitlementsStyles.submitButton} ${addEntitlementsStyles.submitButtonExtended}`}
                    value="Aligni Search"
                    type="submit"
                    disabled={this.props.isLoading}
                />
            </form>
            {this.renderSearchError()}
        </React.Fragment>
    )

    renderPOForm = () => {
        const { purchaseOrder, isLoading } = this.props
        if (Object.entries(purchaseOrder).length > 0) {
            const childs = []
            let keyCounter = 0
            for (let [key, value] of Object.entries(purchaseOrder)) { {/* eslint-disable-line*/}
                childs.push(
                    <React.Fragment key={keyCounter}>
                        <label className={addEntitlementsStyles.componentLabel} > {/* eslint-disable-line*/}
                            {`\n${key}:  `}
                            <input
                                className={addEntitlementsStyles.component}
                                type="text"
                                name={key}
                                disabled
                                value={String(value)}
                                onChange={this.handleChange}
                            />
                        </label>
                    </React.Fragment>,
                )
                keyCounter++
            }
            return (
                <form onSubmit={this.handleSubmit}>
                    <h2 className={sectionStyles.header}>New Lot Entry</h2>
                    {childs}
                    <br />
                    <input
                        className={`${addEntitlementsStyles.submitButton} ${addEntitlementsStyles.submitButtonExtended}`}
                        value="Save to Acroname DB"
                        type="submit"
                        disabled={isLoading}
                    />
                    {this.renderSaveSuccessOrError()}
                </form>
            )
        }
        return null
    }

    render(): React.ReactNode {
        const { isLoading } = this.props
        return (
            <>
                {this.renderSearchForm()}
                {this.renderPOForm()}
                {isLoading ? <LoadingSpinner /> : null}
            </>
        )
    }
}

const mapStateToProps = (state: any): object => (
    {
        getPurchaseOrderError: state.getPurchaseOrderError,
        purchaseOrder: state.purchaseOrder,
        purchaseOrders: state.purchaseOrders,
        prefetchPOError: state.prefetchPOError,
        saveLotNumberError: state.saveLotNumberError,
        saveLotNumberSuccess: state.saveLotNumberSuccess,
        isLoading: state.isLoading,
    }
)

const mapDispatchToProps = (dispatch: Function): object => (
    {
        getAligniPOInfo: (searchTerm: string): void => (
            dispatch(getAligniPOInfo(searchTerm))
        ),
        prefetchAligniPOList: (): void => (
            dispatch(prefetchAligniPOList())
        ),
        saveLotNumber: (purchaseOrder: object): void => (
            dispatch(saveLotNumber(purchaseOrder))
        ),
    }
)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LotNumberForm)

interface ComponentState {
    searchTerm: string
}
